import React from "react"
import PostCard from './postCard'

export default function WelcomeCard({ post }) {
  return (
    <div className="welcome-card">
      <PostCard post={post} />
    </div>
  );
}
