import React from "react"
import Layout from "../components/layout"
import { Link, graphql } from "gatsby"
import AllPosts from "../components/allPosts"
import FeaturedPosts from "../components/featuredPosts"
import Rigs from "../components/rigs"
import LatestPosts from "../components/latestPosts"
import WelcomeCard from "../components/welcomeCard"
import Gallery from "../components/gallery"
import { hotTrackers } from "../config/hot-trackers"

export default function Home({ data }) {
  const { edges: posts } = data.allPosts;
  const welcomePost = data.welcomePost;
  const { edges: rigs } = data.rigs;
  const { edges: featuredPosts} = data.featuredPosts;
  const title = {
    title: 'HOT TRACKERS',
    symbol: '🔥',
    symbolLabel: 'fire'
  };
  return (
    <Layout relatedPages={hotTrackers} name="home" showBurger={true} title={title} blog={true}>
      <div className="blog">
        <LatestPosts posts={featuredPosts} />
        <WelcomeCard post={welcomePost}/>
        <AllPosts posts={posts} />
        <Gallery items={rigs} />
      </div>
    </Layout>
  );
}
export const pageQuery = graphql`
  query blogIndex {
    allPosts: allMdx(sort: {fields: frontmatter___date, order: DESC}) {
      edges {
        node {
          id
          excerpt
          frontmatter {
            title
            date
            slug
            featuredImage {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    welcomePost: mdx(frontmatter: {slug: {eq: "welcome"}}) {
      frontmatter {
        slug
        title
        subtitle
      }
    }
    featuredPosts: allMdx(limit: 5, sort: {fields: frontmatter___date, order: DESC}) {
      edges {
        node {
          id
          excerpt
          frontmatter {
            title
            date
            slug
            featuredImage {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    rigs: allFile(filter: {relativeDirectory: {eq: "posts/images/rigs"}}) {
      edges {
        node {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }

`