import React from "react"
import PostCard from "./postCard"

export default function LatestPosts({ posts }) {
  return (
    <div className="latest-posts">
      {posts.map(({ node: post }) =>
        <PostCard
          key={post.id}
          post={post}
          aspectRatio={16/9} />
        )}
    </div>
  );
}
