import React from "react"
import Img from "gatsby-image"
import get from 'lodash.get';

const GalleryHeader = () => 
  <div className="gallery-header">Community Rigs</div>


const GalleryItem = ({ item }) => {
  let itemImg = get(item, 'childImageSharp.fluid');
  if (!itemImg) return null;
  return (
    <Img fluid={{...itemImg}}/>
  )
}

export default function Gallery({ items }) {
  return (
    <div className="gallery">
      <GalleryHeader />
      <div className="gallery-items">
        {items.map(({ node: item }) =>
          <GalleryItem
            key={item.id}
            item={item} />
        )}
      </div>
    </div>
  );
}
