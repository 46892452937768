export const hotTrackers = [
  {
    name: "Nvidia 30 Series GPUs",
    path: "/hot-list/gaming/graphics-cards/nvidia-geforce-rtx-3090",
    shortName: "Nvidia GPUs"
  },
  /*{
    name: "Radeon GPUs (soon)",
    path: "",
    shortName: "AMD Radeon 6000 Series GPUs"
  },*/
  {
    name: "AMD Ryzen 5000 CPUs",
    path: "/hot-list/gaming/desktop-processors/Ryzen5000",
    shortName: "Ryzen CPUs"
  },
  {
    name: "Microsoft Xbox",
    path: "/hot-list/gaming/game-consoles/microsoft-xbox/xbox-series-x",
    shortName: "Microsoft Xbox"
  },
  {
    name: "Sony Playstation",
    path: "/hot-list/gaming/game-consoles/sony-playstation/ps5-digital",
    shortName: "Sony PS5"
  }
];