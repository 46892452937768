import React from "react"
import Img from "gatsby-image"
import get from 'lodash.get';

export default function PostCard({ post, aspectRatio }) {
  let featuredImgFluid = get(post, 'frontmatter.featuredImage.childImageSharp.fluid');
  return (
    <div className="post-card">
      <a href={post.frontmatter.slug}>
        <span className="link-spanner"></span>
      </a>
      {featuredImgFluid && <Img fluid={{...featuredImgFluid, aspectRatio: aspectRatio }}/>}
      <div className="post-card-title">{post.frontmatter.title}</div>
      {post.frontmatter.subtitle &&
        <div className="post-card-subtitle">{post.frontmatter.subtitle}</div>
      }
    </div>
  );
}
